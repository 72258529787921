import {
  IOrganizationLanguage,
  IProviderLanguages,
  OrganizationLanguage,
  SpeechToTextProviderType,
} from "@auvious/asr";
import { ApiResource, PagedCollection } from "@auvious/common";
import {
  ApplicationProviderTypeEnum,
  TranscriptStateEnum,
  TranscriptTransformType,
} from "../../../core-ui.enums";
import {
  ApplicationService,
  AuviousRtcService,
  GenericErrorHandler,
} from "../../../services";
import {
  IOpenAITranscriptMessage,
  ITranscript,
  ITranscriptMessage,
  ITranscriptStatus,
  ITranscriptTransformStatus,
  ITranscriptTranslateStatus,
  ITransformContent,
} from "../../interfaces";
import {
  IProviderLanguage,
  ProviderOrganizationLanguage,
} from "../../ProviderLanguage";
import {
  IProviderModel,
  IProvisionedModel,
  IProvisionLanguageOptions,
  ITranscriptStrategy,
  ITranscriptTransformOptions,
  ITranscriptTransformStrategy,
  ITranscriptTranslateOptions,
  ITranscriptTranslateStrategy,
} from "../ITranscriptStrategy";
import { IVisionStrategy } from "../IVisionStrategy";
import { AITransformStatus, AITranslationStatus } from "./typings";

interface IOpenAITranscriptStatus {
  id: string;
  conversationId: string;
  state: TranscriptStateEnum;
  language: string;
  createdBy: string;
  createdAt: Date;
}

interface IOpenAIProvisionedLanguages {
  languages: {
    code: string;
    provider: ApplicationProviderTypeEnum;
    usage?: "transcription" | "translation";
    region?: string;
    model?: string;
  }[];
}

class OpenAITranscriptStatus implements ITranscriptStatus {
  constructor(private entity: IOpenAITranscriptStatus) {}
  get id() {
    return this.entity.id;
  }
  get state() {
    return this.entity.state;
  }
  get language() {
    return this.entity.language;
  }
  get createdAt() {
    return new Date(this.entity.createdAt);
  }
}

export class OpenAITranscriptStrategy
  implements
    ITranscriptStrategy,
    ITranscriptTransformStrategy,
    ITranscriptTranslateStrategy,
    IVisionStrategy
{
  private resource: ApiResource;

  constructor(
    private logger: GenericErrorHandler,
    rtc: AuviousRtcService,
    private application: ApplicationService
  ) {
    rtc.common$.subscribe((c) => {
      this.resource = c.apiResourceFactory("api/ai/");
    });
  }

  private get appID() {
    return this.application.getActiveApplication().getId();
  }

  async createTranscriptRequest(
    conversationId: string,
    organizationLanguageId: string
  ): Promise<{ conversationId: string; id: string }> {
    try {
      return await this.resource.create(
        { language: organizationLanguageId },
        {
          urlPostfix: [
            this.appID,
            "conversations",
            conversationId,
            "transcriptions",
          ].join("/"),
        }
      );
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async getTranscriptsForConversation(
    conversationId: string
  ): Promise<ITranscriptStatus[]> {
    try {
      const response: { transcriptions: IOpenAITranscriptStatus[] } =
        await this.resource.get({
          urlPostfix: [
            this.appID,
            "conversations",
            conversationId,
            "transcriptions",
          ].join("/"),
        });
      return response.transcriptions.map((t) => new OpenAITranscriptStatus(t));
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async getTranscriptStatusForConversation(
    conversationId: string,
    transcriptId: string
  ): Promise<ITranscriptStatus> {
    try {
      const response: IOpenAITranscriptStatus = await this.resource.get({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
        ].join("/"),
      });
      return new OpenAITranscriptStatus(response);
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  public async getTranscriptURL(
    conversationId: string,
    transcriptId: string,
    type: "inline" | "attachment"
  ): Promise<{ url: string; validUntil: string }> {
    try {
      return await this.resource.get({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          "signed-url",
        ].join("/"),
      });
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  public async getTranscript(
    conversationId: string,
    transcriptId: string
  ): Promise<ITranscript> {
    try {
      const chatTranscriptList: IOpenAITranscriptMessage[] =
        await this.resource.get({
          urlPostfix: [
            this.appID,
            "conversations",
            conversationId,
            "transcriptions",
            transcriptId,
            "content",
          ].join("/"),
        });
      const transcript: ITranscript = {
        chatTranscriptList,
        conversationId,
        recorderId: undefined,
        instanceId: transcriptId,
      };
      return transcript;
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async removeTranscript(conversationId: string, transcriptId: string) {
    try {
      return await this.resource.delete({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
        ].join("/"),
      });
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  public async getProvisionedLanguages(
    page: number,
    pageSize: number
  ): Promise<PagedCollection<IOrganizationLanguage>> {
    try {
      const provisioned = await this.resource.get({
        urlPostfix: [this.appID, "enabled/languages"].join("/"),
      });

      const languages = (provisioned as IOpenAIProvisionedLanguages).languages
        .filter((l) => l.provider === ApplicationProviderTypeEnum.OPEN_AI)
        .map((language) => {
          return new OrganizationLanguage({
            languageName: undefined,
            module: language.model,
            languageCode: language.code,
            organizationLanguageId: language.code,
            translateProviderType: ApplicationProviderTypeEnum.OPEN_AI,
          });
        });

      return new PagedCollection<IOrganizationLanguage>({
        content: languages,
        totalElements: languages.length,
        totalPages: 1,
        size: languages.length,
        number: 0,
      });
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async getProviderLanguages(
    usage: "translations" | "transcriptions",
    page: number,
    pageSize: number
  ): Promise<PagedCollection<IProviderLanguages>> {
    try {
      const response: {
        code: string;
        region: string;
        name: string;
        model: string;
      }[] = await this.resource.get({
        urlPostfix: [
          this.appID,
          "providers",
          ApplicationProviderTypeEnum.OPEN_AI.toLowerCase(),
          "languages",
        ].join("/"),
        params: { usage },
      });

      const languages: IProviderLanguages[] = response.map((lang) => {
        return {
          id: {
            languageCode: lang.code,
            providerType: SpeechToTextProviderType.OPEN_AI,
          },
          languageName: lang.name,
          model: lang.model,
        };
      });

      return new PagedCollection<IProviderLanguages>({
        content: languages,
        totalElements: languages.length,
        totalPages: 1,
        size: languages.length,
        number: 0,
      });
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async provisionLanguage(
    language: IProviderLanguage,
    options: IProvisionLanguageOptions
  ): Promise<{ organizationLanguageId: string }> {
    try {
      return await this.resource.create(
        {
          provider: ApplicationProviderTypeEnum.OPEN_AI,
          code: language.code,
        },
        { urlPostfix: [this.appID, "enabled/languages"].join("/") }
      );
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async removeProvisionedLanguage(
    provider: ApplicationProviderTypeEnum,
    language: ProviderOrganizationLanguage
  ) {
    try {
      return await this.resource.delete({
        urlPostfix: [this.appID, "enabled/languages", language.code].join("/"),
        params: {
          provider: provider,
        },
      });
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async getProviderModels(
    providerType: ApplicationProviderTypeEnum
  ): Promise<IProviderModel[]> {
    try {
      const response = await this.resource.get({
        urlPostfix: [
          this.appID,
          "providers",
          providerType.toLowerCase(),
          "properties",
        ].join("/"),
      });
      return ((response?.models as IProviderModel[]) || []).filter(
        (m) => m.provider === "OPEN_AI"
      );
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
    }
  }

  async getProvisionedModel(): Promise<IProvisionedModel[]> {
    try {
      const response = await this.resource.get({
        urlPostfix: [this.appID, "enabled/models"].join("/"),
      });
      return ((response?.models as IProviderModel[]) || []).filter(
        (m) => m.provider === "OPEN_AI"
      );
    } catch (ex) {
      if (ex.response?.status === 404) {
        return [];
      }
      this.logger.handleNotAuthenticatedError(ex);
    }
  }

  async provisionModel(
    provider: ApplicationProviderTypeEnum,
    model: IProvisionedModel
  ): Promise<{ organizationModelId: string }> {
    try {
      return await this.resource.create(
        { provider, id: model.id, setDefault: model.usage },
        { urlPostfix: [this.appID, "enabled/models"].join("/") }
      );
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async removeProvisionedModel(
    provider: ApplicationProviderTypeEnum,
    model: IProvisionedModel
  ): Promise<void> {
    try {
      return await this.resource.delete({
        urlPostfix: [
          this.appID,
          "enabled/models",
          model.id,
          "providers",
          provider,
        ].join("/"),
      });
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  /** translations */

  async translate(
    conversationId: string,
    transcriptId: string,
    options: ITranscriptTranslateOptions
  ): Promise<{ conversationId: string; id: string }> {
    try {
      return await this.resource.create(options, {
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          "translations",
        ].join("/"),
      });
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async getTranslations(
    conversationId: string,
    transcriptId: string
  ): Promise<ITranscriptTranslateStatus[]> {
    try {
      const response = await this.resource.get({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          "translations",
        ].join("/"),
      });
      return response["translations"].map((t) => new AITranslationStatus(t));
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async getTranslation(
    conversationId: string,
    transcriptId: string,
    translationId: string
  ): Promise<ITranscriptTranslateStatus> {
    try {
      const response = await this.resource.get({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          "translations",
          translationId,
        ].join("/"),
      });
      return new AITranslationStatus(response["translation"]);
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async getTranslationURL(
    conversationId: string,
    transcriptId: string,
    translationId: string
  ): Promise<{ url: string; validUntil: string }> {
    try {
      return await this.resource.get({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          "translations",
          translationId,
          "signed-url",
        ].join("/"),
      });
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async getTranslationContent(
    conversationId: string,
    transcriptId: string,
    translationId: string
  ): Promise<ITranscriptMessage[]> {
    try {
      return await this.resource.get({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          "translations",
          translationId,
          "content",
        ].join("/"),
      });
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async removeTranslation(
    conversationId: string,
    transcriptId: string,
    translationId: string
  ): Promise<void> {
    try {
      return await this.resource.delete({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          "translations",
          translationId,
        ].join("/"),
      });
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  /** transformations */

  async transformTranscriptForConversation(
    conversationId: string,
    transcriptId: string,
    options?: ITranscriptTransformOptions
  ) {
    try {
      return await this.resource.create(options, {
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          "prompts",
        ].join("/"),
      });
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async getTransformsForConversation(
    conversationId: string,
    transcriptId: string
  ): Promise<ITranscriptTransformStatus[]> {
    try {
      const response = await this.resource.get({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          "prompts",
        ].join("/"),
      });
      return response["prompts"].map((t) => new AITransformStatus(t));
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async getTransformStatusForConversation(
    conversationId: string,
    transcriptId: string,
    transformId: string
  ): Promise<ITranscriptTransformStatus> {
    try {
      const response = await this.resource.get({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          "prompts",
          transformId,
        ].join("/"),
      });
      return new AITransformStatus(response["prompt"]);
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async getTransformURL(
    conversationId: string,
    transcriptId: string,
    transformId: string
  ) {
    try {
      return await this.resource.get({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          "prompts",
          transformId,
          "signed-url",
        ].join("/"),
      });
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async getTransformContent(
    conversationId: string,
    transcriptId: string,
    transformId: string
  ): Promise<ITransformContent> {
    try {
      return await this.resource.get({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          "prompts",
          transformId,
          "content",
        ].join("/"),
      });
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  async removeTransform(
    conversationId: string,
    transcriptId: string,
    transformId: string
  ): Promise<void> {
    try {
      return await this.resource.delete({
        urlPostfix: [
          this.appID,
          "conversations",
          conversationId,
          "transcriptions",
          transcriptId,
          "prompts",
          transformId,
        ].join("/"),
      });
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }

  isFeatureSupported(feature: TranscriptTransformType): boolean {
    return ["sentiment", "summary", "prompt", "translation", "vision"].includes(
      feature
    );
  }

  /** vision */
  async recognize(prompt: string, imageBase64: string, model?: string) {
    try {
      return await this.resource.create(
        {
          prompt,
          image_url: imageBase64,
          model,
        },
        {
          urlPostfix: [this.appID, "vision", "recognition"].join("/"),
        }
      );
    } catch (ex) {
      this.logger.handleNotAuthenticatedError(ex);
      throw ex;
    }
  }
}
