<ng-container *ngIf="visionCount > 0">
  <av-tooltip
    [class.hidden]="isHidden"
    position="bottom"
    message="Frame analyzed"
  >
    <button
      [attr.aria-label]="'Frame analyzed' | translate"
      data-tid="tile-info/vision-count"
      avButton
      [disabled]="isWhisper"
      (click)="toggleAssistant()"
      color="primary"
      size="xxs"
      ofType="icon"
    >
      <av-icon class="tile-icon" mode="light" name="prompt"></av-icon>
    </button>
  </av-tooltip>
</ng-container>

<ng-container *ngIf="!isScreen && snapshotCount > 0">
  <av-tooltip [class.hidden]="isHidden" position="bottom">
    <button
      avButton
      color="primary"
      size="xxs"
      ofType="icon"
      (click)="snapshotRequest()"
      class="tile-info"
      [class.tile-info-action]="!isWhisper"
      [attr.aria-label]="
        ('Number of snapshots taken is ' | translate) + snapshotCount
      "
      data-tid="tile-info/snapshot-count"
    >
      <av-icon class="tile-icon" mode="light" name="camera"></av-icon>
    </button>

    <ng-container tooltipMessage *ngIf="snapshotCount === 1">
      {{ "snapshot taken" | translate }}
    </ng-container>
    <ng-container tooltipMessage *ngIf="snapshotCount > 1">
      {{ "snapshots taken" | translate }}
    </ng-container>
  </av-tooltip>
</ng-container>

<div class="tile-info-group" *ngIf="isGroupOneAvailable()">
  <div class="tile-info" *ngIf="isDebugRtc">
    <av-tooltip position="bottom">
      rtc
      <div class="debug" tooltipMessage>
        <div>id: {{ stream.id }}</div>
        <div>username: {{ stream.originator.username }}</div>
        <div>my username: {{ myself.username }}</div>
        <div>endpoint: {{ stream.originator.endpoint }}</div>
        <div *ngIf="!!stream.mediaStream">
          media stream: {{ stream.mediaStream.id }}
        </div>
        <div>local: {{ isLocal }}</div>
        <div>type: {{ stream.type }}</div>
        <!-- <div>cam off: {{isMutedStream(stream, kindVideo)}}</div> -->
        <!-- <div>mic off: {{isMutedStream(stream, kindAudio)}}</div> -->
        <div>
          tag muted: {{ stream.originator.endpoint === myself.endpoint }}
        </div>
      </div>
    </av-tooltip>
  </div>

  <ng-container *ngIf="!isScreen">
    <div
      [attr.aria-label]="'Participant is agent' | translate"
      class="tile-info"
      [class.hidden]="isHidden"
      *ngIf="isRoleAgentVisible"
      data-tid="tile-info/role-agent"
    >
      <av-tooltip position="bottom" message="AGENT">
        <av-icon class="tile-icon" mode="light" name="headset"></av-icon>
      </av-tooltip>
    </div>

    <div
      class="tile-info"
      [class.hidden]="isHidden"
      *ngIf="isNetworkIndicatorVisible"
    >
      <app-network-indicator
        [endpoint]="stream.originator.endpoint"
      ></app-network-indicator>
    </div>

    <span
      role="alert"
      class="tile-alert"
      [attr.aria-label]="'Participant\'s microphone is muted' | translate"
      *ngIf="isMutedMicAria() && !isLocal"
    >
    </span>

    <span
      role="alert"
      class="tile-alert"
      [attr.aria-label]="'Participant\'s camera is muted' | translate"
      *ngIf="isMutedCam() && !isLocal"
    >
    </span>

    <div
      class="tile-info"
      [attr.aria-label]="'Participant\'s microphone is muted' | translate"
      *ngIf="isMutedMic()"
      data-tid="tile-info/mic-muted"
    >
      <av-icon class="tile-icon" mode="light" name="mic-mute"></av-icon>
    </div>

    <div
      class="tile-info"
      [attr.aria-label]="'Participant has enabled closed captions' | translate"
      [class.hidden]="isHidden"
      *ngIf="isCaptionsOn"
      data-tid="tile-info/captions-on"
    >
      <av-icon class="tile-icon" mode="light" name="cc"></av-icon>
    </div>

    <div
      class="tile-info tile-info-colors"
      [class.tile-info-colors-local]="isLocal"
      [attr.aria-label]="'Participant\'s marker color' | translate"
      *ngIf="color() && isPointerActive"
      data-tid="tile-info/pointer-color"
    >
      <av-tooltip message="Pointer color" position="bottom">
        <app-point-color
          (click)="isLocal && isColorPickerOpen.set(!isColorPickerOpen())"
          [color]="color()"
        ></app-point-color>
      </av-tooltip>

      <av-card
        *ngIf="isLocal && isColorPickerOpen()"
        [popup]="true"
        [backdrop]="false"
        vAlign="bottom"
        hAlign="center"
        arrow="top"
      >
        <av-card-body size="sm">
          <app-point-color
            [color]="c"
            (click)="changeColor(c)"
            [class.active]="c === color()"
            [class.disabled]="isColorDisabled(c) && c !== color()"
            *ngFor="let c of colors()"
          ></app-point-color>
        </av-card-body>
      </av-card>
    </div>

    <div
      [attr.aria-label]="
        ('Participant\'s language is ' | translate) + participantLanguage
      "
      class="tile-info tile-info-lang"
      [class.hidden]="isHidden"
      *ngIf="isParticipantLanguageEnabled"
      data-tid="tile-info/user-language"
    >
      <av-tooltip position="bottom">
        {{ participantLanguage }}
        <ng-container tooltipMessage>
          {{ "Customer's language is" | translate }}
          {{ participantLanguage | translate }}
        </ng-container>
      </av-tooltip>
    </div>
  </ng-container>

  <div role="group" class="tile-info fx-row fx-gap-10" *ngIf="isWarning()">
    <av-tooltip position="bottom" *ngIf="isViewerDisabled">
      <div class="fx-row fx-align-center">
        <div class="warning">!</div>
        <small
          *ngIf="!isTileTooSmall"
          class="warning-label tile-info-tiny-hidden tile-info-pointer-hidden"
          translate
          >Poor Connection</small
        >
      </div>
      <ng-container tooltipMessage>
        <small [innerHTML]="'The video will resume automatically' | translate">
        </small>
      </ng-container>
    </av-tooltip>

    <av-tooltip
      [wrap]="true"
      position="bottom"
      *ngIf="isEndpointSick() || isStreamSick() || isStreamFailed()"
    >
      <div class="fx-row fx-align-center">
        <div *ngIf="isStreamSick() || isEndpointSick()" class="warning">!</div>
        <div *ngIf="isStreamFailed()" class="warning error">!</div>
        <small
          *ngIf="!isTileTooSmall"
          class="warning-label tile-info-tiny-hidden tile-info-pointer-hidden"
          translate
          >Unstable network/device</small
        >
      </div>
      <ng-container tooltipMessage>
        <small
          [innerHTML]="
            'The video quality will recover automatically' | translate
          "
        >
        </small>
      </ng-container>
    </av-tooltip>
  </div>
</div>
<!-- </ng-container> -->
