<ng-container *ngIf="!!tag?.color">
  <div #color class="color"></div>
</ng-container>
<label>
  {{ tag?.label }}
  <ng-content></ng-content>
</label>

<!-- && !remove.observed -->
<button
  *ngIf="plus && !active"
  avButton
  ofType="icon"
  size="xxs"
  data-tid="tag/add"
>
  <av-icon name="plus"></av-icon>
</button>

<button
  *ngIf="actionIcon"
  avButton
  ofType="icon"
  size="xxs"
  data-tid="tag/action"
>
  <av-icon [name]="actionIcon"></av-icon>
</button>

<!-- || remove.observed -->
<ng-container *ngIf="active">
  <button
    class="remove"
    avButton
    ofType="icon"
    size="xxs"
    (click)="tryRemove($event)"
    data-tid="tag/remove"
  >
    <av-icon name="close-big"></av-icon>
  </button>
  <av-icon
    class="tick"
    [mode]="isLight() ? 'dark' : 'light'"
    name="tick"
  ></av-icon>
</ng-container>
