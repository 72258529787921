<app-callback
  *ngIf="isCallbackRequest"
  [@slideInOut]="true"
  [inCall]="true"
  [hover]="true"
  [interaction]="interaction"
  (close)="closeCallbackPanel()"
>
</app-callback>

<div
  class="share-link-message fx-row fx-align-center"
  [@slideInOut]="true"
  *ngIf="isCopied()"
>
  <av-icon size="lg" mode="dark" name="tick"></av-icon>
  <span translate>Copied</span>
</div>

<av-tooltip message="Share video link" position="right" [enabled]="!shareOpen">
  <button
    [attr.aria-label]="
      (isShareToggleActive() ? 'Close' : 'Open') + ' link share menu'
        | translate
    "
    avButton
    ofType="icon"
    [size]="buttonSize"
    [color]="isShareToggleActive() ? 'basic' : 'primary'"
    class="room-control-min-size"
    id="btn-share-link-toggle"
    (click)="toggleShare()"
    data-tid="conference/toggle-share"
    [raised]="true"
    #menuButtonRef
  >
    <av-icon name="link" *ngIf="!isShareToggleActive()"></av-icon>
    <av-icon
      name="close-big"
      mode="dark"
      *ngIf="isShareToggleActive()"
    ></av-icon>
  </button>
</av-tooltip>

<av-card
  [popup]="true"
  vAlign="top"
  [menu]="true"
  class="share-options"
  *ngIf="shareOpen()"
  [@slideInOut]="true"
  #menuRef
>
  <av-card-header (close)="openChange(false)">
    {{ "Share your room link" | translate }}
  </av-card-header>
  <av-card-body>
    <av-menu>
      <ng-container *ngIf="isCustomerLinkAvailable">
        <av-menu-item type="header">{{
          "Invite a customer" | translate
        }}</av-menu-item>

        <ng-container *ngIf="isSendChatWidgetAvailable">
          <app-share-link-widget
            [interaction]="interaction"
            [ticket]="customerTicket"
            (completed)="completed()"
          ></app-share-link-widget>
        </ng-container>

        <ng-container *ngIf="isSendChatAvailable">
          <app-share-link-chat
            [interaction]="interaction"
            [link]="customerLink"
            (completed)="completed()"
            (openChange)="openChange($event)"
          ></app-share-link-chat>
        </ng-container>

        <ng-container *ngIf="isQRCustomerAvailable">
          <app-share-link-qr
            [interaction]="interaction"
            [link]="customerLink"
            (completed)="completed()"
            (openChange)="openChange($event)"
          ></app-share-link-qr>
        </ng-container>

        <ng-container *ngIf="isEmailAvailable">
          <app-share-link-email
            [interaction]="interaction"
            [link]="customerLink"
            (completed)="completed()"
            (openChange)="openChange($event)"
            (confirm)="confirmEmail($event)"
          ></app-share-link-email>
        </ng-container>

        <ng-container *ngIf="isSMSAvailable">
          <app-share-link-sms
            [interaction]="interaction"
            [link]="customerLink"
            (completed)="completed()"
            (openChange)="openChange($event)"
            (confirm)="confirmSMS($event)"
          ></app-share-link-sms>
        </ng-container>

        <ng-container *ngIf="isCallbackAvailable">
          <app-share-link-schedule
            (request)="callbackRequest()"
          ></app-share-link-schedule>
        </ng-container>

        <av-menu-item
          icon="copy-document"
          (select)="copyCustomerLink()"
          [attr.aria-label]="'Copy customer link to clipboard' | translate"
          data-tid="conference/copy-customer-link"
        >
          <span translate>Copy to clipboard</span>
        </av-menu-item>
      </ng-container>

      <ng-container *ngIf="isAgentLinkAvailable">
        <av-menu-item type="separator"></av-menu-item>

        <av-menu-item type="header">
          <span translate>Invite an agent</span>
        </av-menu-item>

        <av-menu-item
          icon="copy-document"
          (select)="copyAgentLink()"
          [attr.aria-label]="'Copy agent link to clipboard' | translate"
          data-tid="conference/copy-agent-link"
        >
          <span translate>Copy to clipboard</span>
        </av-menu-item>

        <av-menu-item
          *ngIf="isQRAgentAvailable"
          icon="barcode"
          (select)="showQR()"
          data-tid="conference/show-qr"
          [attr.aria-label]="'Show QR code' | translate"
        >
          <span translate>Show QR code</span>
        </av-menu-item>
      </ng-container>
    </av-menu>
  </av-card-body>
</av-card>

<app-share-link-sms-number
  *ngIf="isPhoneNumberValidation"
  [link]="customerLink"
  [interaction]="interaction"
  [queue]="smsQueue"
  [channel]="channel"
  [workflow]="workflow"
  (completed)="completed()"
  (dismiss)="isPhoneNumberValidation = false"
></app-share-link-sms-number>

<app-share-link-email-address
  *ngIf="isEmailValidation"
  [link]="customerLink"
  [interaction]="interaction"
  [channel]="channel"
  [workflow]="workflow"
  (completed)="completed()"
  (dismiss)="isEmailValidation = false"
></app-share-link-email-address>

<input id="customer-link" type="hidden" [value]="customerLink" />
<input id="agent-link" type="hidden" [value]="agentLink" />
