import { Injectable } from "@angular/core";
import { Observable, Subject, BehaviorSubject, firstValueFrom } from "rxjs";
import { filter, map } from "rxjs/operators";

import { Util } from "@auvious/common";

import { IPoint, IArea, IPosition, ICoordinates } from "../models/IPoint";
import { AuviousRtcService } from "./rtc.service";
import { debugError } from "./utils";
import { ConferenceService } from "./conference.service";
import { BaseEvent } from "../models/IEvent";
import { IEndpoint, StreamType } from "@auvious/rtc";
import { COLOR, UserCapabilityEnum, UserRoleEnum } from "../core-ui.enums";
import { PointerColorMetadata, PointerMetadata } from "../models/Metadata";
import { ThemeService } from "./theme.service";
import { IStreamMetadata, ThemeParam } from "../models";

// import { Tracker } from '@auvious/media-tools';

function eventToPointTrasformer(event: ArPointerIndicatedEvent): IPoint {
  return {
    id: `${event.index}`,
    coordinates: event.coordinates,
    area: event.area,
    streamType: event.targetStreamType,
    streamCorrelationId: event.targetStreamCorrelationId,
    endpoint: event.targetUserEndpointId,
    index: event.index,
    timeToLive: event.ttl,
    color: event.color,
  };
}

export class ArPointerActivatedEvent extends BaseEvent {
  public static type = "ArPointerActivatedEvent";
  constructor(
    public targetUserId: string,
    public targetUserEndpointId: string,
    public targetStreamType: string,
    public senderUserEndpointId: string,
    public targetStreamCorrelationId: string
  ) {
    super("ArPointerActivatedEvent");
  }
}

export class ArPointerDeactivatedEvent extends BaseEvent {
  public static type = "ArPointerDeactivatedEvent";
  constructor(
    public targetUserId: string,
    public targetUserEndpointId: string,
    public targetStreamType: string,
    public senderUserEndpointId: string,
    public targetStreamCorrelationId: string
  ) {
    super("ArPointerDeactivatedEvent");
  }
}

export class ArPointerIndicatedEvent extends BaseEvent {
  public static type = "ArPointerIndicatedEvent";
  constructor(
    public targetUserId: string,
    public targetUserEndpointId: string,
    public targetStreamType: string,
    public targetStreamCorrelationId: string,
    public coordinates: ICoordinates,
    public area: IArea,
    public index: number,
    public ttl: number,
    public color: string
  ) {
    super("ArPointerIndicatedEvent");
  }
}

export class ArPointerFreezeFrameChangedEvent extends BaseEvent {
  public static type = "ArPointerFreezeFrameChangedEvent";
  constructor(
    public targetUserId: string,
    public targetUserEndpointId: string,
    public targetStreamCorrelationId: string,
    public on: boolean
  ) {
    super("ArPointerFreezeFrameChangedEvent");
  }
}

@Injectable()
export class PointerService {
  private pointSize = 30;

  private _points = new Subject<IPoint>();
  public pointAvailable$ = this._points.asObservable();

  private _pointArea = new Subject<
    ArPointerActivatedEvent | ArPointerDeactivatedEvent
  >();
  public pointAreaChange$ = this._pointArea.asObservable();

  private _pointColorsChanged = new BehaviorSubject<{
    [endpoint: string]: string;
  }>({});
  public pointColorsChange$ = this._pointColorsChanged.asObservable();

  private _freezeFrame = new Subject<{
    target: IEndpoint;
    correlationId: string;
    on: boolean;
  }>();
  public freezeFrameChange$ = this._freezeFrame.asObservable();

  private _zoomFrame = new Subject<{
    target: IEndpoint;
    correlationId: string;
    on: boolean;
  }>();
  public zoomFrameChange$ = this._zoomFrame.asObservable();

  private pointIndex: number;
  private pointTimeToLive = 5;
  private targetActiveMap: Map<string, StreamType> = new Map(); // { endpoint id: StreamTypes }
  private requesterActiveMap: Map<string, boolean> = new Map(); // { endpoint id: boolean }
  private targetActiveStreamMap: Map<string, string> = new Map(); // { endpoint id: correlation id }
  private colorClaimMap: { [endpoint: string]: string } = {};
  private availableColors: string[] = [];
  private frozenFramesMap: Map<string, string> = new Map(); // { [target: string]: boolean } = {}
  private zoomFramesMap: Map<string, string> = new Map();

  constructor(
    private rtcService: AuviousRtcService,
    private conferenceService: ConferenceService,
    private theme: ThemeService
  ) {
    firstValueFrom(this.rtcService.getEventObservableAvailable()).then(
      (eventObservable) => {
        eventObservable
          .pipe(
            filter(
              (data) => data?.payload?.type === ArPointerIndicatedEvent.type
            ),
            map((data) => eventToPointTrasformer(data.payload))
          )
          .subscribe((point) => this._points.next(point));

        eventObservable
          .pipe(
            filter(
              (e) => e?.payload?.type === ArPointerFreezeFrameChangedEvent.type
            ),
            map((e) => e.payload as ArPointerFreezeFrameChangedEvent)
          )
          .subscribe((e) =>
            this.freezeFrame(
              {
                username: e.targetUserId,
                endpoint: e.targetUserEndpointId,
              },
              e.targetStreamCorrelationId,
              false
            )
          );
      }
    );

    this.conferenceService.conferenceMetadataSet$
      .pipe(filter((data) => data instanceof PointerMetadata))
      .subscribe((data: PointerMetadata) => {
        this.targetActiveMap.set(
          data.target.endpoint,
          data.streamType as StreamType
        );
        this.targetActiveStreamMap.set(
          data.target.endpoint,
          data.streamCorrelationId
        );
        this.requesterActiveMap.set(data.userEndpointId, true);
        this.pointIndex = 0;
        const action = new ArPointerActivatedEvent(
          data.target.username,
          data.target.endpoint,
          data.streamType as string,
          data.userEndpointId,
          data.streamCorrelationId
        );
        this._pointArea.next(action);
      });

    this.conferenceService.conferenceMetadataSet$
      .pipe(filter((data) => data instanceof PointerColorMetadata))
      .subscribe((data: PointerColorMetadata) => {
        this.colorClaimMap = data.colorClaims;
        this._pointColorsChanged.next(this.colorClaimMap);
      });

    this.conferenceService.conferenceMetadataRemoved$
      .pipe(filter((data) => data instanceof PointerMetadata))
      .subscribe((data: PointerMetadata) => {
        this.targetActiveMap.delete(data.target.endpoint);
        this.targetActiveStreamMap.delete(data.target.endpoint);
        this.requesterActiveMap.delete(data.userEndpointId);
        const action = new ArPointerDeactivatedEvent(
          data.target.username,
          data.target.endpoint,
          data.streamType as string,
          data.userEndpointId,
          data.streamCorrelationId
        );
        // notify change
        this._pointArea.next(action);
      });

    this.conferenceService.conferenceMetadataRemoved$
      .pipe(filter((data) => data instanceof PointerColorMetadata))
      .subscribe(() => {
        // clear claims
        this.colorClaimMap = {};
        this._pointColorsChanged.next(this.colorClaimMap);
      });

    this.conferenceService.endpointJoined$
      .pipe(
        filter(
          (_) =>
            this.isStarted &&
            !!this.requesterActiveMap.get(this.rtcService.endpoint())
        )
      )
      .subscribe((originator) => {
        const color = this.colorClaimMap[originator.endpoint];

        // in a scenario where we joined the meeting after the claims have been handed over, claim a color
        if (!color) {
          let claimedColor;
          const claimedColors: string[] = Object.keys(
            this.colorClaimMap
          ).reduce((arr, key) => [...arr, this.colorClaimMap[key]], []);

          const diff = this.availableColors.filter(
            (c) => !claimedColors.includes(c)
          );

          claimedColor = diff.length > 0 ? diff[0] : COLOR.RED;

          this.claimColor(claimedColor, originator);
        }
      });

    // if the remote stream was removed while on pointer and I am the requester, notify everybody to stop
    this.conferenceService.streamRemoved$
      .pipe(
        filter(
          (s) =>
            this.isTarget(
              s.originator.endpoint,
              s.type,
              this.targetActiveStreamMap.get(s.originator.endpoint)
            ) && this.isRequester(this.rtcService.endpoint())
        )
      )
      .subscribe((s) => {
        this.togglePointArea(
          s.originator.endpoint,
          s.type,
          this.targetActiveStreamMap.get(s.originator.endpoint),
          this.rtcService.endpoint()
        );
      });

    this.theme
      .themeChanged$()
      .pipe(filter((t) => !!t))
      .subscribe(
        (theme) => (this.availableColors = theme[ThemeParam.AR_POINTER_COLORS])
      );
  }

  public freezeFrame(target: IEndpoint, correlationId: string, notify = true) {
    let targetId = this.frozenFramesMap.get(target.endpoint);

    if (targetId === correlationId) {
      this.frozenFramesMap.delete(target.endpoint);
      targetId = undefined;

      // stop zoom if on
      if (this.zoomFramesMap.get(target.endpoint) === correlationId) {
        this.zoomFramesMap.delete(target.endpoint);
        this._zoomFrame.next({ target, correlationId, on: false });
      }
    } else {
      this.frozenFramesMap.set(target.endpoint, (targetId = correlationId));
    }

    const on = targetId === correlationId;

    this._freezeFrame.next({ target, correlationId, on });

    // notify remote participant
    if (notify) {
      this.notifyFreezeFrameChanged(target, correlationId, on);
    }
  }

  public zoomFrame(target: IEndpoint, correlationId: string) {
    let targetId = this.zoomFramesMap.get(target.endpoint);

    if (targetId === correlationId) {
      this.zoomFramesMap.delete(target.endpoint);
      targetId = undefined;
    } else {
      this.zoomFramesMap.set(target.endpoint, (targetId = correlationId));
    }

    const on = targetId === correlationId;

    this._zoomFrame.next({ target, correlationId, on });
  }

  public claimColor(color: string, originator: IEndpoint){
    this.colorClaimMap[originator.endpoint] = color;

    // update conference metadata
    const meta = new PointerColorMetadata(
      this.conferenceService.myself,
      this.colorClaimMap
    );

    this.conferenceService.setConferenceMetadata(meta);

    this._pointColorsChanged.next(this.colorClaimMap);
  }

  private notifyFreezeFrameChanged(
    target: IEndpoint,
    correlationId: string,
    on: boolean
  ) {
    const changedEvent = new ArPointerFreezeFrameChangedEvent(
      target.username,
      target.endpoint,
      correlationId,
      on
    );
    // notify all participants except myself
    const participants = this.conferenceService.getParticipants();
    Object.keys(participants)
      .filter((key) => key !== this.rtcService.endpoint())
      .forEach((key) => {
        this.rtcService.sendEventMessage(
          participants[key].username,
          key,
          changedEvent
        );
      });
  }

  public point(
    event: MouseEvent,
    endpoint: string,
    streamType: string,
    streamCorrelationId: string
  ) {
    try {
      this.pointIndex += 1;
      const point: IPoint = {
        id: Util.uuidgen(),
        coordinates: {
          x: event.offsetX,
          y: event.offsetY,
        },
        area: {
          // @ts-expect-error
          width: event.target.clientWidth || event.target.offsetWidth,
          // @ts-expect-error
          height: event.target.clientHeight || event.target.offsetHeight,
        },
        endpoint,
        streamType,
        streamCorrelationId,
        index: this.pointIndex,
        timeToLive: this.pointTimeToLive,
      };
      this.sendPointEvent(point);
    } catch (ex) {
      debugError(ex);
    }
  }

  public togglePointArea(
    endpoint: string,
    streamType: StreamType,
    streamCorrelationId: string,
    requesterEndpoint: string
  ) {
    this.pointIndex = 0;

    let targetExists = this.targetActiveStreamMap.get(endpoint);
    if (targetExists === streamCorrelationId) {
      this.targetActiveStreamMap.delete(endpoint);
      this.targetActiveMap.delete(endpoint);
      targetExists = undefined;
    } else {
      this.targetActiveMap.set(endpoint, (targetExists = streamType));
      this.targetActiveStreamMap.set(endpoint, streamCorrelationId);
    }

    const requesterExists = this.requesterActiveMap.get(requesterEndpoint);
    if (requesterExists) {
      this.requesterActiveMap.delete(requesterEndpoint);
    } else {
      this.requesterActiveMap.set(requesterEndpoint, true);
    }

    this.sendPointAreaChangeEvent(
      endpoint,
      streamType,
      streamCorrelationId,
      !!targetExists,
      requesterEndpoint
    );
  }

  public end(requesterEndpoint: string) {
    this.targetActiveMap.forEach((streamType, endpoint) => {
      this.togglePointArea(
        endpoint,
        streamType,
        this.targetActiveStreamMap.get(endpoint),
        requesterEndpoint
      );
    });
  }

  private sendPointEvent(point: IPoint) {
    const endpoints = this.conferenceService.getParticipants();
    const targetEndpoint = endpoints[point.endpoint];
    const targetUserId = targetEndpoint.username;
    const targetUserEndpointId = targetEndpoint.endpoint;

    point.color = this.colorClaimMap[this.rtcService.endpoint()];

    const event = new ArPointerIndicatedEvent(
      targetUserId,
      targetUserEndpointId,
      point.streamType,
      point.streamCorrelationId,
      point.coordinates,
      point.area,
      point.index,
      point.timeToLive,
      point.color
    );

    // deliver to all participants of the current conference, except me
    Object.values(endpoints)
      .filter((origin) => origin.endpoint !== this.rtcService.endpoint())
      .forEach((endpoint) =>
        this.rtcService.sendEventMessage(
          endpoint.username,
          endpoint.endpoint,
          event
        )
      );

    // also deliver to myself
    this._points.next(point);
  }

  private sendPointAreaChangeEvent(
    targetEndpointId: string,
    targetStreamType: StreamType,
    targetStreamCorrelationId: string,
    enabled: boolean,
    senderEndpointId: string
  ) {
    const endpoints = this.conferenceService.getParticipants();
    const targetEndpoint = endpoints[targetEndpointId];
    // target may have left the moment we send the event
    if (!targetEndpoint) {
      return;
    }

    const { username, endpoint } = targetEndpoint;

    // assign colors to participants
    if (enabled) {
      Object.keys(endpoints).forEach((e, index) => {
        if (index < this.availableColors.length) {
          this.colorClaimMap[e] = this.availableColors[index];
        }
      });
    } else {
      this.colorClaimMap = {};
    }

    const event = enabled
      ? new ArPointerActivatedEvent(
          username,
          endpoint,
          targetStreamType,
          senderEndpointId,
          targetStreamCorrelationId
        )
      : new ArPointerDeactivatedEvent(
          username,
          endpoint,
          targetStreamType,
          senderEndpointId,
          targetStreamCorrelationId
        );

    // also deliver to myself
    // relying on the event to activate, adds a few miliseconds of lag, faster this way
    this._pointArea.next(event);

    // update conference metadata
    const meta = new PointerMetadata(
      this.conferenceService.myself,
      { username, endpoint }, // filters out targetEndpoint extra metadata
      targetStreamType,
      targetStreamCorrelationId
    );

    // set pointer color metadata
    const colorMeta = new PointerColorMetadata(
      this.conferenceService.myself,
      this.colorClaimMap
    );
    if (enabled) {
      this.conferenceService.setConferenceMetadata(meta);
      this.conferenceService.setConferenceMetadata(colorMeta);
      this._pointColorsChanged.next(this.colorClaimMap);
    } else {
      this.conferenceService.removeConferenceMetadata(meta.key);
      this.conferenceService.removeConferenceMetadata(colorMeta.key);
      this._pointColorsChanged.next({});
      // clear frozen frame if exists
      if (
        this.frozenFramesMap.get(targetEndpointId) === targetStreamCorrelationId
      ) {
        this.freezeFrame(targetEndpoint, targetStreamCorrelationId);
      }
    }
  }

  public draw(area: IArea, point: IPoint): IPosition {
    try {
      const ratioX = area.width / point.area.width;
      const ratioY = area.height / point.area.height;
      const position: IPosition = {
        top: point.coordinates.y * ratioY - this.pointSize / 2,
        left: point.coordinates.x * ratioX - this.pointSize / 2,
        bottom: 0,
        right: 0,
      };
      return position;
    } catch (ex) {
      debugError(ex);
    }
  }

  public cleanup() {
    this.colorClaimMap = {};
    this._pointColorsChanged.next(this.colorClaimMap);
    this.targetActiveMap.clear();
    this.targetActiveStreamMap.clear();
    this.requesterActiveMap.clear();
    this.frozenFramesMap.clear();
  }

  /*
    public draw2(area: IArea, point: IPoint, streamId: string): Observable<IPosition> {
        return new Observable(observer => {
            try {
                const ratioX = area.width / point.area.width;
                const ratioY = area.height / point.area.height;
                let position: IPosition = {
                    top: point.coordinates.y * ratioY - this.pointSize / 2,
                    left: point.coordinates.x * ratioX - this.pointSize / 2,
                    bottom: 0,
                    right: 0
                };

                const positionFix: IPosition = { top: 0, left: 0, right: 0, bottom: 0 };

                // tracker addition
                const streamElem = this.conferenceService.getStreamElementById(streamId);
                const videoElem = streamElem?.element as HTMLVideoElement;

                debug('tracker start position ', position);
                observer.next(position);

                if (!videoElem) {
                    return;
                }
                const tracker = new Tracker(videoElem);

                tracker.onupdate = (id, x, y) => {

                    const trackRatioX = area.width / videoElem.videoWidth;
                    const trackRatioY = area.height / videoElem.videoHeight;

                    const top = Math.floor(y * trackRatioY);
                    const left = Math.floor(x * trackRatioX);

                    if (positionFix.left === 0 && positionFix.top === 0) {
                        // first time
                        positionFix.left = position.left - left;
                        positionFix.top = position.top - top;
                    }

                    position = {
                        top: top + positionFix.top,
                        left: left + positionFix.left,
                        bottom: 0,
                        right: 0
                    };
                    debug('tracker new position ', position);
                    observer.next(position);
                };

                const videoRatioX = videoElem.videoWidth / point.area.width;
                const videoRatioY = videoElem.videoHeight / point.area.height;

                const id = tracker.track(
                    point.coordinates.x * videoRatioX,
                    point.coordinates.y * videoRatioY,
                    50);

                // store id, we need it to untrack
                this.trackerMap = {
                    ...this.trackerMap,
                    [point.id]: {
                        tracker,
                        id
                    }
                };

            } catch (ex) {
                debugError(ex);
            }
        });
    }

    public untrack(point: IPoint) {
        const trackerInstance = this.trackerMap[point.id];
        if (!trackerInstance) { return; }
        trackerInstance.tracker.untrack(trackerInstance.id);
        trackerInstance.tracker.destroy();
        delete this.trackerMap[point.id];
    } */

  public supported(endpoint: IEndpoint): boolean {
    const roles = endpoint?.metadata?.roles || [];
    const capabilities = endpoint?.metadata?.capabilities || [];

    const hasCustomerRole = roles.includes(UserRoleEnum.customer);
    const hasQrCapability = capabilities.includes(UserCapabilityEnum.arPointer);

    return hasCustomerRole && hasQrCapability;
  }

  public get isStarted() {
    return this.targetActiveMap.size > 0;
    // return Object.keys(this.targetActive).reduce((val, key) => this.targetActive[key] || val, false);
  }
  public isTarget(
    endpointId: string,
    type: StreamType,
    correlationId?: string
  ) {
    return (
      this.targetActiveMap.get(endpointId) === type &&
      (!correlationId ||
        this.targetActiveStreamMap.get(endpointId) === correlationId)
    );
  }
  public isRequester(endpointId: string) {
    return !!this.requesterActiveMap.get(endpointId);
  }

  public getColorForEndpoint(endpointId: string): string {
    return this.colorClaimMap[endpointId];
  }
}
