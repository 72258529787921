import { Injectable, NgZone } from "@angular/core";
import {
  BehaviorSubject,
  distinctUntilChanged,
  fromEvent,
  map,
  Observable,
  Subject,
} from "rxjs";
import { IPosition } from "../models";

export type FormFactor = "mobile" | "tablet" | "desktop";
@Injectable()
export class WindowService {
  public isWindowPopedOut;

  private _streamControlsContainerPositionChange: Subject<IPosition> =
    new Subject();
  public streamControlsContainerPositionChange$: Observable<IPosition> =
    this._streamControlsContainerPositionChange.asObservable();

  private onClickElements = new Set<{
    elm: HTMLElement;
    resolve: () => void;
  }>();

  private _formFactorChange = new BehaviorSubject<FormFactor>(
    this.getFormFactor()
  );
  public formFactorChange$ = this._formFactorChange.asObservable();

  constructor(private ngZone: NgZone) {
    this.listenToResize();
  }

  private listenToResize() {
    fromEvent(window, "resize")
      .pipe(
        map(() => this.getFormFactor()),
        distinctUntilChanged()
      )
      .subscribe((formFactor) => {
        this.ngZone.run(() => this._formFactorChange.next(formFactor));
      });
  }

  public notifyStreamControlsContainerPositionChange(position: IPosition) {
    this._streamControlsContainerPositionChange.next(position);
  }

  private getFormFactor(): FormFactor {
    const width = window.innerWidth;
    if (width <= 599) return "mobile";
    if (width <= 959) return "tablet";
    return "desktop";
  }

  private onClickedHandler = (event: PointerEvent) => {
    let clickedOutside = 0;

    for (const entry of this.onClickElements) {
      if (!entry.elm.isConnected) {
        this.onClickElements.delete(entry);
      } else if (event.composedPath().includes(entry.elm)) {
        break;
      } else {
        clickedOutside++;
      }
    }

    if (clickedOutside === this.onClickElements.size) {
      for (const entry of this.onClickElements) {
        entry.resolve();
      }

      this.onClickElements.clear();

      document.body.removeEventListener("click", this.onClickedHandler, {
        capture: true,
      });
    }
  };

  public onClickedOutside(...elms: HTMLElement[]) {
    document.body.removeEventListener("click", this.onClickedHandler, {
      capture: true,
    });

    document.body.addEventListener("click", this.onClickedHandler, {
      capture: true,
    });

    return new Promise<void>((resolve) => {
      for (const elm of elms) this.onClickElements.add({ elm, resolve });
    });
  }
}
