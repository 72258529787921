import {
  Component,
  Output,
  EventEmitter,
  HostBinding,
  Input,
  HostListener,
  ElementRef,
  ViewChild,
  Renderer2,
  AfterViewInit,
  OnInit,
  signal,
} from "@angular/core";
import { ITag } from "../../../../core-ui/models";
import { TinyColor } from "@ctrl/tinycolor";

@Component({
  selector: "av-tag",
  templateUrl: "./tag.component.html",
  styleUrls: ["./tag.component.scss"],
})
export class TagComponent implements OnInit, AfterViewInit {
  @Input() tag: ITag;
  @Input()
  set active(value: boolean) {
    this.isActive = value;
    if (!this.tag?.color) {
      return;
    }
    this.renderer.setStyle(
      this.host.nativeElement,
      "background-color",
      this.isActive ? this.tag?.color : null
    );
  }
  get active(): boolean {
    return this.isActive;
  }

  @Input() actionIcon: string;

  @Input() plus: boolean;

  @Output() remove: EventEmitter<ITag> = new EventEmitter();
  @Output() add: EventEmitter<ITag> = new EventEmitter();
  @Output() action: EventEmitter<ITag> = new EventEmitter();

  @ViewChild("color") colorElemRef?: ElementRef<HTMLDivElement>;

  private isActive: boolean;
  isLight = signal(false);
  isTicked = signal(false);

  constructor(
    private renderer: Renderer2,
    private host: ElementRef<HTMLDivElement>
  ) {}

  @HostBinding("class") get class() {
    return {
      "tag-active": this.active,
      "tag-clickable": this.add.observed || this.action.observed,
      "tag-light": this.isLight(),
      "tag-colorless": !this.tag?.color,
      "tag-ticked": this.isTicked(),
    };
  }

  @HostListener("click")
  click() {
    if (this.add.observed || this.plus) {
      this.isTicked.set(true);
      setTimeout(() => {
        this.isTicked.set(false);
      }, 1000);
      this.add.emit(this.tag);
    }
    if (this.action.observed) {
      this.action.emit(this.tag);
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.tag?.color) {
      this.renderer.setStyle(
        this.colorElemRef.nativeElement,
        "background-color",
        this.tag?.color
      );
      const color = new TinyColor(this.tag?.color);
      this.isLight.set(color.isLight());
    }
  }

  tryRemove(event: Event) {
    event.stopPropagation();
    this.remove.emit(this.tag);
  }
}
