<av-option-block
  *ngFor="let o of options"
  [label]="o.body"
  [active]="isActive(o)"
>
  <input
    type="checkbox"
    name="{{ question.id }}"
    [ngModel]="o.value"
    (ngModelChange)="optionChange($event, o)"
  />
</av-option-block>
<div class="form-error" *ngIf="isError()" translate>Required</div>
