import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from "@angular/core";
import { ITZonePickerEntity } from "../../../../core-ui/models/interfaces";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-timezone-picker",
  templateUrl: "./timezone-picker.component.html",
  styleUrls: ["./timezone-picker.component.scss"],
})
export class TimezonePickerComponent implements OnInit, AfterViewInit {
  @Input() timezone: string;
  @Output() changed: EventEmitter<string> = new EventEmitter();

  isDarkMode = false;
  isOpen = false;
  isNotify = false;

  query: ITZonePickerEntity;
  // queryBackup: string;
  timezones: ITZonePickerEntity[];
  // timezonesFiltered: ITZonePickerEntity[];
  notFoundText: string;
  placeholderText: string;

  constructor(translate: TranslateService) {
    this.notFoundText = translate.instant("Not found");
    this.placeholderText = translate.instant("Select time zone");
  }

  ngOnInit(): void {
    this.timezones = this.mapTimezones();
    // this.timezonesFiltered = [...this.timezones];
    if (this.timezone) {
      this.query = this.timezones.find((t) => t.nameValue === this.timezone);
    }
    // else {
    // notify on after view init
    // }
  }

  ngAfterViewInit(): void {
    if (!this.timezone) {
      const tz = moment.tz.guess();
      this.query = this.timezones.find((t) => t.nameValue === tz);
      this.changed.emit(tz);
    }
  }

  mapTimezones(): ITZonePickerEntity[] {
    return moment.tz.names().map((tz) => ({
      // what to show
      name: this.prettyName(tz),
      // actual value
      nameValue: tz,
      // what to query
      abbr: tz.toLowerCase().split("_").join(" "),
    }));
  }

  prettyName(name: string): string {
    return name.split("_").join(" ").split("/").join(" / ");
  }

  /*
  search(query: string) {
    this.timezonesFiltered = !query
      ? this.timezones
      : this.timezones.filter((tz) => tz.abbr.includes(query.toLowerCase()));
  }

  searchFocus() {
    this.isOpen = true;
    this.queryBackup = this.query;
    this.query = null;
    this.search(null);
  }

  async searchBlur() {
    if (!this.query) {
      this.query = this.queryBackup;
    }
    this.isOpen = false;
  }
*/

  timezoneChange(zone: ITZonePickerEntity) {
    // this.query = zone.name;
    this.changed.emit(zone.nameValue);
    // this.isOpen = false;
  }

  onChangeSearch(t) {
    // without this search does not work correctly (always one step back...)
  }
}
