import {
  ITranscriptTransformStatus,
  ITranscriptTranslateStatus,
} from "../../interfaces";

export class AITransformStatus implements ITranscriptTransformStatus {
  constructor(private entity: ITranscriptTransformStatus) {}
  get id() {
    return this.entity.id;
  }
  get state() {
    return this.entity.state;
  }
  get intent() {
    return this.entity.intent;
  }
  get createdAt() {
    return new Date(this.entity.createdAt);
  }
  get updatedAt() {
    return new Date(this.entity.updatedAt);
  }
  get transcript() {
    return this.entity.transcript;
  }
}

export class AITranslationStatus implements ITranscriptTranslateStatus {
  constructor(private entity: ITranscriptTranslateStatus) {}
  get id() {
    return this.entity.id;
  }
  get state() {
    return this.entity.state;
  }
  get language() {
    return this.entity.language;
  }
  get createdAt() {
    return new Date(this.entity.createdAt);
  }
  get updatedAt() {
    return new Date(this.entity.updatedAt);
  }
  get transcript() {
    return this.entity.transcript;
  }
}
