<div
  class="autocomplete-container"
  aria-owns="suggestions suggestions-history"
  [attr.aria-expanded]="isOpen"
  [ngClass]="{ active: isOpen }"
>
  <div class="input-container">
    <input
      #searchInput
      avInput
      type="text"
      attr.aria-label="{{ placeholder }}"
      aria-autocomplete="list"
      role="combobox"
      placeholder="{{ placeholder }}"
      [(ngModel)]="query"
      (input)="onChange($event)"
      (focus)="handleFocus($event)"
      (blur)="onTouched($event)"
      [disabled]="disabled"
      autocomplete="off"
    />
    <div
      class="x"
      *ngIf="query && !isLoading && !disabled"
      (click)="remove($event)"
    >
      <av-icon size="md" name="close"></av-icon>
    </div>
    <!--Loading mask-->
    <av-spinner size="sm" *ngIf="isLoading"></av-spinner>
  </div>

  <!--FilteredList items-->
  <av-card
    [backdrop]="false"
    class="suggestions-container"
    id="suggestions"
    role="listbox"
    [ngClass]="{
      'is-hidden': isHistoryListVisible(),
      'is-visible': !isHistoryListVisible()
    }"
  >
    <!--FilteredList heading-->
    <div class="heading" *ngIf="filteredList.length > 0 && heading">
      <div class="text">{{ heading }}</div>
    </div>

    <ul #filteredListElement>
      <li *ngFor="let item of filteredList; let idx = index" class="item">
        <!--string logic-->
        <div
          [class.complete-selected]="idx === selectedIdx"
          *ngIf="isTypeString(item)"
          (click)="select(item)"
        >
          <ng-container
            *ngTemplateOutlet="
              itemTemplate;
              context: { $implicit: item | highlight : toHighlight }
            "
          >
          </ng-container>
        </div>
        <!--object logic-->
        <div
          [class.complete-selected]="idx === selectedIdx"
          *ngIf="!isTypeString(item)"
          (click)="select(item)"
        >
          <ng-container
            *ngTemplateOutlet="
              itemTemplate;
              context: {
                $implicit: item | highlight : toHighlight : searchKeyword
              }
            "
          >
          </ng-container>
        </div>
      </li>
    </ul>
  </av-card>

  <!--HistoryList items-->
  <div
    class="suggestions-container"
    id="suggestions-history"
    role="listbox"
    [ngClass]="{
      'is-hidden': !isHistoryListVisible(),
      'is-visible': isHistoryListVisible()
    }"
  >
    <!--HistoryList heading-->
    <div class="heading" *ngIf="historyList.length > 0 && historyHeading">
      <div class="text">{{ historyHeading }}</div>
      <div class="x" (click)="resetHistoryList($event)">
        <i class="material-icons" [attr.aria-label]="'Delete' | translate"
          >delete</i
        >
      </div>
    </div>

    <ul #historyListElement>
      <li *ngFor="let item of historyList; let idx = index" class="item">
        <!--string logic-->
        <div
          [class.complete-selected]="idx === selectedIdx"
          *ngIf="isTypeString(item)"
          (click)="select(item)"
        >
          <ng-container
            *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"
          >
          </ng-container>
        </div>
        <!--object logic-->
        <div
          [class.complete-selected]="idx === selectedIdx"
          *ngIf="!isTypeString(item)"
          (click)="select(item)"
        >
          <ng-container
            *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"
          >
          </ng-container>
        </div>
        <div class="x" (click)="removeHistoryItem(idx, $event)">
          <i class="material-icons" [attr.aria-label]="'Close' | translate"
            >close</i
          >
        </div>
      </li>
    </ul>
  </div>

  <!--Not found-->
  <av-card
    [backdrop]="false"
    class="not-found"
    *ngIf="isLoading ? !isLoading && notFound : notFound"
  >
    <ng-container
      *ngTemplateOutlet="notFoundTemplate; context: { $implicit: notFoundText }"
    >
    </ng-container>
  </av-card>
</div>
<div
  class="autocomplete-overlay"
  *ngIf="overlay"
  (click)="handleOverlay()"
></div>
