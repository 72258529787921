import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AppConfigService } from "../../../core-ui";
import { ErrorPageCode } from "../../../app/app.enums";

@Injectable({
  providedIn: "root",
})
export class RedirectGuard {
  constructor(private config: AppConfigService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const code = route.paramMap.get('code');
    if (code === ErrorPageCode.PAGE_NOT_FOUND && this.config.pageNotFoundRedirectURL) {
      window.location.href = this.config.pageNotFoundRedirectURL;
      return false;
    }
    return true;
  }
}
