import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { AngularCropperjsModule } from "angular-cropperjs";
import "moment/locale/de";
import "moment/locale/el";
import "moment/locale/es";
import "moment/locale/fi";
import "moment/locale/nl";
import "moment/locale/pl";
import "moment/locale/sv";
import "moment/locale/tr";
import { DpDatePickerModule } from "ng2-date-picker";
import { ColorTwitterModule } from "ngx-color/twitter";
import { MomentModule } from "ngx-moment";

import {
  AssetComponent,
  ButtonComponent,
  CardBodyComponent,
  CardFooterComponent,
  CardHeaderComponent,
  CardRowColumnComponent,
  CardRowResponsiveComponent,
  CardRowComponent,
  CardComponent,
  ConfirmComponent,
  CopyClipboardComponent,
  ExpansionPanelComponent,
  FilterItemComponent,
  FlipComponent,
  IconComponent,
  ImageComponent,
  InputComponent,
  MenuItemComponent,
  MenuComponent,
  ModalContainerComponent,
  PagerComponent,
  SearchComponent,
  SwitchComponent,
  TabGroupComponent,
  TabComponent,
  TableRowComponent,
  TableComponent,
  TagComponent,
  TooltipComponent,
  SpinnerComponent,
  PortalComponent,
  ControlRowComponent,
  ControlSectionComponent,
  AutocompleteComponent,
  HighlightPipe,
  ColorPickerComponent,
  CaptionItemComponent,
  CaptionsComponent,
  TimezonePickerComponent,
  AgentFilterComponent,
  DurationFilterComponent,
  DateRangePickerComponent,
  DateRangePickerPopupComponent,
  CompositionRecordingComponent,
  CompositionRowComponent,
  SnapshotComponent,
  MapComponent,
  MetadataBuilderComponent,
  MetadataBuilderPairComponent,
  CompositionTranscriptComponent,
  CompositionTranscriptTransformComponent,
  CompositionStateComponent,
  LocalePickerComponent,
  CompositionTranscriptContentComponent,
  OptionBlockComponent
} from "./components";

import { DurationPipe, OrderByPipe, MarkdownPipe } from "./pipes";

import {
  ThemableLogoDirective,
  PortalHostDirective,
  SearchableDirective,
  FileDragDropDirective,
  OverflowDetectDirective,
  TrapFocusDirective,
  TrimInputDirective,
} from "./directives";

@NgModule({
  declarations: [
    AssetComponent,
    ButtonComponent,
    CardBodyComponent,
    CardComponent,
    CardFooterComponent,
    CardHeaderComponent,
    CardRowColumnComponent,
    CardRowComponent,
    CardRowResponsiveComponent,
    ConfirmComponent,
    CopyClipboardComponent,
    ExpansionPanelComponent,
    FilterItemComponent,
    FlipComponent,
    IconComponent,
    ImageComponent,
    InputComponent,
    MenuComponent,
    MenuItemComponent,
    ModalContainerComponent,
    PagerComponent,
    SearchComponent,
    SwitchComponent,
    TabComponent,
    TabGroupComponent,
    TableComponent,
    TableRowComponent,
    TagComponent,
    TooltipComponent,
    SpinnerComponent,
    PortalComponent,
    ControlRowComponent,
    ControlSectionComponent,
    AutocompleteComponent,
    ColorPickerComponent,
    CaptionItemComponent,
    CaptionsComponent,
    TimezonePickerComponent,
    AgentFilterComponent,
    DurationFilterComponent,
    DateRangePickerComponent,
    DateRangePickerPopupComponent,
    CompositionRecordingComponent,
    CompositionRowComponent,
    SnapshotComponent,
    MapComponent,
    MetadataBuilderComponent,
    MetadataBuilderPairComponent,
    LocalePickerComponent,
    CompositionTranscriptComponent,
    CompositionTranscriptTransformComponent,
    CompositionStateComponent,
    LocalePickerComponent,
    CompositionTranscriptComponent,
    CompositionTranscriptTransformComponent,
    CompositionStateComponent,
    CompositionTranscriptContentComponent,
    OptionBlockComponent,

    //
    HighlightPipe,
    DurationPipe,
    OrderByPipe,
    MarkdownPipe,
    PortalHostDirective,
    SearchableDirective,
    ThemableLogoDirective,
    FileDragDropDirective,
    OverflowDetectDirective,
    TrapFocusDirective,
    TrimInputDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    AngularCropperjsModule,
    TranslateModule,
    ColorTwitterModule,
    MomentModule,
    DpDatePickerModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    TranslateModule,
    DpDatePickerModule,
    MomentModule,

    // components
    CardFooterComponent,
    CardRowComponent,
    TabComponent,
    TabGroupComponent,
    ModalContainerComponent,
    TooltipComponent,
    CardRowResponsiveComponent,
    CardComponent,
    FilterItemComponent,
    ExpansionPanelComponent,
    ImageComponent,
    FlipComponent,
    AssetComponent,
    TagComponent,
    ConfirmComponent,
    MenuItemComponent,
    MenuComponent,
    SwitchComponent,
    ButtonComponent,
    InputComponent,
    IconComponent,
    CardRowColumnComponent,
    CardBodyComponent,
    PagerComponent,
    SearchComponent,
    TableComponent,
    CardHeaderComponent,
    TableRowComponent,
    CopyClipboardComponent,
    SpinnerComponent,
    PortalComponent,
    ControlRowComponent,
    ControlSectionComponent,
    AutocompleteComponent,
    ColorPickerComponent,
    CaptionItemComponent,
    CaptionsComponent,
    TimezonePickerComponent,
    AgentFilterComponent,
    DurationFilterComponent,
    DateRangePickerComponent,
    CompositionRecordingComponent,
    CompositionRowComponent,
    SnapshotComponent,
    MapComponent,
    MetadataBuilderComponent,
    MetadataBuilderPairComponent,
    LocalePickerComponent,
    CompositionTranscriptComponent,
    CompositionTranscriptTransformComponent,
    CompositionTranscriptContentComponent,
    OptionBlockComponent,

    // pipes
    HighlightPipe,
    DurationPipe,
    OrderByPipe,
    MarkdownPipe,

    // directives
    PortalHostDirective,
    SearchableDirective,
    ThemableLogoDirective,
    FileDragDropDirective,
    OverflowDetectDirective,
    TrapFocusDirective,
    TrimInputDirective,
  ],
})
export class SharedModule {}
