import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from "@angular/core";

@Component({
  selector: "av-option-block",
  templateUrl: "./option.component.html",
  styleUrls: ["./option.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionBlockComponent {
  @Input() label: string;
  @Input() active: boolean;

  @HostBinding("class") get class() {
    return {
      active: this.active,
    };
  }
}
