import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { TinyColor } from "@ctrl/tinycolor";

@Component({
  selector: "app-point-color",
  templateUrl: "./point-color.component.html",
  styleUrls: ["./point-color.component.scss"],
})
export class PointColorComponent implements AfterViewInit {
  @Input()
  set color(value: string) {
    this._color = value;
    if (this.pointRef) this.setColor(value);
  }

  get color() {
    return this._color;
  }

  @ViewChild("point") pointRef: ElementRef<HTMLDivElement>;

  private _color: string;
  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.setColor(this.color);
  }

  setColor(c: string) {
    const color = new TinyColor(c);
    this.renderer.setStyle(
      this.pointRef.nativeElement,
      "background-color",
      color.toRgbString()
    );
    this.renderer.setStyle(
      this.pointRef.nativeElement,
      "box-shadow",
      `0 0 0 4px ${color.setAlpha(0.5).toRgbString()}`
    );
  }
}
