<form #f="ngForm" class="fx-row fx-align-end fx-flex-1 fx-gap-10">
  <av-control-row type="text" label="Key" class="fx-flex-1">
    <div class="fx-column fx-flex-1 fx-gap-sm">
      <input
        required
        avInput
        #keyRef="ngModel"
        name="key"
        [(ngModel)]="item.key"
        (ngModelChange)="update()"
        type="text"
      />
      <div
        translate
        class="form-error"
        *ngIf="keyRef.touched && keyRef.hasError('required')"
      >
        Required
      </div>
    </div>
  </av-control-row>

  <av-control-row type="text" label="Value" class="fx-flex-2">
    <div class="fx-column fx-flex-1 fx-gap-sm">
      <input
        required
        avInput
        #valueRef="ngModel"
        name="value"
        [(ngModel)]="item.value"
        (ngModelChange)="update()"
        type="text"
      />
      <div
        translate
        class="form-error"
        *ngIf="valueRef.touched && valueRef.hasError('required')"
      >
        Required
      </div>
    </div>
  </av-control-row>

  <ng-container *ngIf="hasType">
    <av-control-row type="text" label="Type" class="fx-flex-1">
      <!-- <label class="fx-row fx-align-end fx-gap-10"
        ><span translate>Type</span>
        <ng-content></ng-content>
      </label> -->
      <select
        avInput
        name="type"
        #typeRef="ngModel"
        [(ngModel)]="item.type"
        (ngModelChange)="update()"
      >
        <option
          *ngFor="let o of types"
          [ngValue]="o.value"
          [disabled]="o.disabled"
        >
          {{ o.label }}
        </option>
      </select>
      <div
        translate
        class="form-error"
        *ngIf="typeRef.touched && typeRef.hasError('required')"
      >
        Required
      </div>
    </av-control-row>
  </ng-container>

  <div class="form-item">
    <label>&nbsp;</label>
    <button
      [attr.aria-label]="'Delete pair' | translate"
      avButton
      ofType="icon"
      size="xs"
      color="danger"
      data-tid="metadata/item/remove"
      (click)="remove()"
    >
      <av-icon name="trash"></av-icon>
    </button>
  </div>
</form>
